import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent {
  constructor( 
    private dialogRef: MatDialogRef<TutorialComponent>,
    private userS: UsersService
  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
  }

  code: string = "";
  userObj!: any;
  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if(this.code=='Tutorial') {
        this.dialogRef.close();
      }
      this.code="";
    } else {
      this.code += event.key;
    }
  }
}
