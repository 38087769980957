import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { UsersService } from './users.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class StationsService {

  constructor(
    private usrService : UsersService,
    private main: MainService
  ) { }

  getStationData() : any {
    return this.main.getRequest("/packing/station/");
  }

  getBoxData(box_id: string) {
    return this.main.getRequest("/packing/boxscan/" + box_id);
  }

  setBoxStatus(box_obj: any) {
    return this.main.postRequest(box_obj, "/packing/boxscan");
  }

  setBoxCompleted(box_obj: any) {
    return this.main.postRequest(box_obj, "/packing/boxscan/completed");
  }

  uploadBoxVideo(form : FormData){
    return this.main.postRequestFormData(form, "/packing/boxscan/video/upload");
  }

  setPickPtL(pick: any) {
    return this.main.postRequestExt(pick, "/pick/");
  }
}
