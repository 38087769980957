import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TutorialComponent } from '../shared/dialogs/tutorial/tutorial.component';
import { UsersService } from 'src/app/services/users.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StationsService } from 'src/app/services/stations.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeyeventComponent } from '../shared/snackbars/keyevent/keyevent.component';
import ysFixWebmDuration from 'fix-webm-duration'

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent {

  @ViewChild('video') videoElementRef!: ElementRef;
  videoElement!: HTMLVideoElement;
  recordVideoElement!: HTMLVideoElement;
  isRecording: boolean = false;
  mediaRecorder: any;
  recordedBlobs!: Blob[];
  downloadUrl!: string;
  stream!: MediaStream;
  tutorialOpen: boolean = true;
  startTime: any = Date.now();

  startRecording() {
    this.recordedBlobs = [];
    let options: any = { mimeType: 'video/webm' };
    
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
      this.mediaRecorder.start(); // collect 100ms of data
      this.startTime = Date.now();
      this.isRecording = !this.isRecording;
      this.onDataAvailableEvent();
      this.onStopRecordingEvent();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error con la webcam',
        text: 'Ocurrió un error al intentar iniciar la grabacion.',
      }).then(() => {
        this.logOut();
      });
    }
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.isRecording = !this.isRecording;
  }

  
  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  prepareVideo(videoBuffer: Blob) {
    this.bodyForm.append("blob", videoBuffer as File);
    this.bodyForm.append("id_campana", this.userObj.id_campana);
    this.bodyForm.append("id_box", this.idBoxOpen.toString());
    this.bodyForm.append("init", this.boxOpenAt);
    this.boxScanLog.push({ "type": "box_close", time: new Date() });
    this.bodyForm.append("scan_log", JSON.stringify(this.boxScanLog));
    
    const ins_obj = { 
      "id_box": this.idBoxOpen.toString(), 
      "id_campana": this.userObj.id_campana,
      "init": this.boxOpenAt,
      "scan_log": JSON.stringify(this.boxScanLog)
    }
    this.stationS.setBoxCompleted(ins_obj).subscribe(
      (result:any)=>{
        if(result.message == 'Success') {
          Swal.fire({
            icon: 'info',
            title: 'Caja completada',
            text:'Esta caja finalizó el proceso.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 1000,
          });      
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al cerrar la caja, favor de informar al equipo de soporte.',
            timer: 3000,
            showConfirmButton: false
          });
        }
      },
      (err:any)=>{
        this.main.HideLoading();
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al cerrar la caja, favor de informar al equipo de soporte.',
          timer: 3000,
          showConfirmButton: false
        });
    });
    if(this.cloud_video) {
      this.stationS.uploadBoxVideo(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message != 'Success') {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el video, favor de informar al equipo de soporte.',
              timer: 3000,
              showConfirmButton: false
            });
            this.downloadFile(videoBuffer);
            console.log(result);
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el video, favor de informar al equipo de soporte.',
            timer: 3000,
            showConfirmButton: false
          });
          this.downloadFile(videoBuffer);
          console.log(err);
        });
      }
      else {
        this.downloadFile(videoBuffer);
      }
  }

  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = (event: Event) => {
        navigator.mediaDevices.getUserMedia({
          video: true
        })
        .then(stream => {
          let videoBuffer = new Blob(this.recordedBlobs, { type: 'video/webm' });
          this.prepareVideo(videoBuffer);
         })
        .catch(err=> {
          Swal.fire({
            icon: 'error',
            title: 'Error con la webcam',
            text: 'Algo ocurrio con la Webcam, favor de volverla a conectar.',
          }).then(() => {
            this.logOut();
          });
        });
      };
    } catch (error) {
      console.log(error);
    }
  }

  downloadFile(data: Blob): void {
    const fileName: string = this.nameCamp + "_" + this.idBoxOpen.toString() + "_" + this.consecutivo.toString() + ".webm";
    const objectUrl: string = URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    }, 100);
  }
  
  displayedColumns: string[] = ['position', 'sku', 'name', 'pick', 'faltante'];

  station: any = {};
  code: string = "";
  rowH: string = "";
  materials: any = {};
  grid_mat: any = [];
  listMaterials: any = [];
  listMaterialsAv: any = [];
  listMaterialsMs: any = [];
  boxOpen: boolean = false;
  boxObj: any = [];
  box_obj_ins: any = {};
  box_readonly: boolean = false;
  nameCamp!: string;
  idBoxOpen: number = 0;
  boxOpenAt!: any;
  boxScanLog: any = [];
  next_scan: any = {};
  next_scan_id = 0;
  box_id_title = "";
  cloud_video: boolean = true;
  consecutivo: number = 0;
  userObj!: any;
  line_status!: any;
  form!: FormGroup;
  bodyForm : FormData = new FormData(); 
  constructor(
    private stationS: StationsService,
    private userS: UsersService,
    private main: MainService,
    private matDialog: MatDialog,
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

   logOut() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }   

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.getSationData();
      
  }

  openDialogTutorial() {
    this.matDialog.open(TutorialComponent, {panelClass: 'dialogs-lg', disableClose: true }).afterClosed().subscribe(
      resp => {    
        this.tutorialOpen = false;           
      }
    );
  }

  getSationData() {
    this.stationS.getStationData().subscribe((x: any) => {
      if(x.data) {
        this.station = x.data;

        if(this.userObj.tipo==='q'){
          navigator.mediaDevices
            .getUserMedia({
              video: {
                height: x.config.calidad_video
              }
            })
            .then(stream => {
              this.videoElement = this.videoElementRef.nativeElement;
              this.stream = stream;
              this.videoElement.srcObject = this.stream;
            })
            .catch(err=> {
              Swal.fire({
                icon: 'error',
                title: 'Error con la webcam',
                text: 'Ocurrió un error al intentar iniciar la grabacion.',
              }).then(() => {
                this.logOut();
              });
            });
        }

        this.materials = this.station.materiales?this.station.materiales:[];
        this.loadGrid();
        this.rowH = (this.userObj.columnas==1?(this.userObj.filas*2).toString()+":1":(this.userObj.filas+1).toString() + ":" + (this.userObj.columnas-1).toString());
        this.openDialogTutorial();
      }
      else this.logOut();
    },
    (err:any) => {
      this.main.HideLoading();
      console.log(err);
      this.logOut();
    });
  }

  loadGrid() {
    this.grid_mat = [];
    for (let f = 1; f <= this.userObj.filas; f++) {
      for (let c = 1; c <= this.userObj.columnas; c++) {
        let obj_m = this.materials.find((m:any)=>m.row==f&&m.col==c);
        if(!obj_m) {
          obj_m = {row: f, col: c}
        }              
        this.grid_mat.push(obj_m);
      }          
    }
  }

  loadBox(box_id: string) {
    this.main.ShowLoading();
    this.box_id_title = box_id;
    this.stationS.getBoxData(box_id).subscribe((x: any) => {
      this.main.HideLoading();
      if(x.message=="Completed") {
        Swal.fire({
          icon: 'warning',
          title: "Caja Completada",
          text: 'Esta caja finalizó el proceso.',
          timer: 3000,
          showConfirmButton: false
        });
      }
      else if(x.data?.length > 0) {
        const alerts = x.alerts.find((e:any)=>x.data.some((m:any)=>m.tipo==e.tipo&&m.id==e.id_rel));
        if(alerts) { //Si hay que sacar la caja por alguna alerta
          Swal.fire({
            icon: 'warning',
            title: 'Retirar la caja de la linea.',
            text: alerts.alerta,
            timer: 5000,
            showConfirmButton: false
          });
        }
        else {//Si no hay alerta toma el curso normal
          if(this.userObj.tipo==='q') {
              this.cloud_video = x.cloud_video==1;
              this.userObj.id_campana = x.campana;
              this.materials = x.data.map((e:any)=> Object.assign({}, e, {"pick": (e.complete?(e.cantidad-(e.pick_omit?e.pick_omit:0)):0), "id": (e.id?e.id:e.bag_id), "pick_omit": 0,  }));
              this.materials = this.materials.map((e:any)=>Object.assign({}, e, {"complete": e.cantidad==e.pick}));
              this.loadGrid();
              this.startRecording();            
              if(x.box_completed) {
                this.box_readonly = true;
              }
          }
          else {
            this.materials = this.station.materiales.map((e:any)=> Object.assign({}, e, {"pick": 0, "pick_omit": 0 }, x.data.find((m:any)=>e.sku===m.sku)));
            this.loadGrid();
          }
          this.listMaterials = this.materials.filter((e:any)=>e.cantidad>0);
          this.listMaterialsAv = this.listMaterials.filter((e:any)=>e.faltante!=2);
          this.listMaterialsMs = this.listMaterials.filter((e:any)=>e.faltante==2);
          this.consecutivo = x.consecutivo;
          if(this.listMaterials.length > 0) {
            this.boxOpen = true;
            this.idBoxOpen = parseInt(box_id);
            this.boxObj = x.data;
            this.boxOpenAt = new Date();
            this.boxScanLog = [{ "type": "box_open", time: new Date() }];
            this.next_scan = this.listMaterials.find((e:any)=>e.pick<e.cantidad);
            this.next_scan_id = this.next_scan?this.next_scan.id:0;
            this.line_status = x.line;
            this.nameCamp = x.nombre_campana;

            const obj_ptl = {
              "fila": this.next_scan.row.toString(),
              "columna": this.next_scan.col.toString(),
              "sku": this.next_scan.sku
            }
            this.stationS.setPickPtL(obj_ptl).subscribe((result: any) => { 
              console.log(result);
            },
            (err:any) => {
              console.log(err);
            });          
          }
          else {
            this.boxScanLog.push({ "type": "box_close", time: new Date() });
            this.box_obj_ins = { "id_box": parseInt(box_id), "id_station": this.station.id_dist_racks, "status": 1, "hasMat": false, "init": this.boxOpenAt, "scan_log": this.boxScanLog };
            this.saveStatus(this.box_obj_ins);
          }
        }
      }
      else if(x.next_st) {
        Swal.fire({
          icon: 'warning',
          title: `Esta caja debe estar en ${x.next_st.nombre}.`,
          text: 'Por favor, regresala.',
          timer: 3000,
          showConfirmButton: false
        });
      }
      else {
        Swal.fire({
          icon: 'warning',
          title: 'No se encontró esta caja',
          text: 'El código de caja ingresado no coincide con ninguno existente en el sistema.',
          timer: 3000,
          showConfirmButton: false
        });
      }
    },
    (err:any) => {
      this.main.HideLoading();
      Swal.fire({
        icon: 'warning',
        title: 'No se encontró esta caja',
        text: 'El código de caja ingresado no coincide con ninguno existente en el sistema.',
        timer: 3000,
        showConfirmButton: false
      });
       
    })
  }

  saveStatus(box_obj_ins: any) {
    
      this.boxScanLog.push({ "type": "box_close", time: new Date() });
      this.stationS.setBoxStatus(box_obj_ins).subscribe((x: any) => {
        this.main.HideLoading();
        if(box_obj_ins.hasMat) {
          if(this.userObj.tipo=='q') {
            Swal.fire({
              icon: 'success',
              title: 'Estación finalizada',
              text: 'El contenido de la caja ha sido actualizado.',
              timer: 1000,
              showConfirmButton: false
            });
          }
          else {
            Swal.fire({
              icon: 'success',
              title: 'Estación finalizada',
              text: 'Pasar caja a la siguiente estación.',
              timer: 1000,
              showConfirmButton: false
            });
          }
        }
        else {
          Swal.fire({
            icon: 'success',
            title: '¡No se requiere material de esta estación!',
            text: 'Pasar caja a la siguiente estación.',
            timer: 3000,
            showConfirmButton: false
          });
        }
      },
      (err:any) => {
        this.main.HideLoading();
        Swal.fire({
          icon: 'error',
          title: 'No se pudo guardar la caja',
          text: 'Ocurrió un error, por favor vacía la caja y vuelve a intentarlo nuevamente.',
          timer: 3000,
          showConfirmButton: false
        });
      });
      this.cleanBox();
  }

  scanProduct(matScan: any) {
    if(this.next_scan&&(this.next_scan.id>0&&this.next_scan.id===matScan.id||(this.next_scan.bag_id>0&&this.next_scan.bag_id===matScan.bag_id)||matScan.sku==="omitir")) {
      this.boxScanLog.push({ "type": "mat_scan", time: new Date(), "scan": matScan.sku, "sku": this.next_scan.sku });
      const m_scan = this.materials.find((e:any)=>e.sku===this.next_scan.sku);
      m_scan.pick_omit = matScan.sku==="omitir"?(m_scan.cantidad - m_scan.pick):0;
      m_scan.pick = matScan.sku==="omitir"?(m_scan.pick?m_scan.pick:0):(matScan.pick+1);
      this.loadGrid();
      this.boxObj.find((e:any)=>e.id===m_scan.id&&e.tipo===m_scan.tipo).pick_omit = m_scan.pick_omit;
      this.boxObj.find((e:any)=>e.id===m_scan.id&&e.tipo===m_scan.tipo).pick = m_scan.pick;
      this.listMaterials = this.materials.filter((e:any)=>e.cantidad>0);
      this.listMaterialsAv = this.listMaterials.filter((e:any)=>e.faltante!=2);
      this.listMaterialsMs = this.listMaterials.filter((e:any)=>e.faltante==2);
      this.next_scan = this.listMaterials.find((e:any)=>(e.pick+(e.pick_omit?e.pick_omit:0))<e.cantidad);
      if(this.next_scan) {
        const obj_ptl = {
          "fila": this.next_scan.row.toString(),
          "columna": this.next_scan.col.toString(),
          "sku": this.next_scan.sku
        }
        this.stationS.setPickPtL(obj_ptl).subscribe((result: any) => { 
          console.log(result);
        },
        (err:any) => {
          console.log(err);
        });          
      }

      this.next_scan_id = this.next_scan?this.next_scan.id:0;
      const listMatFaltantes = this.materials.filter((e:any)=>(e.pick+(e.pick_omit?e.pick_omit:0))<e.cantidad);

      if(listMatFaltantes.length === 0) {
        const upd_mat = this.boxObj.filter((e:any)=> e.cantidad===(e.pick+e.pick_omit)).map((e:any)=> {
          const mat_pos = this.materials.find((m:any)=>m.id===e.id);
          return Object.assign( {"id": e.id, "bag_id": e.bag_id, "cantidad": e.cantidad, "tipo": e.tipo, "pick": (e.pick?e.pick:0), "pick_omit": (e.pick_omit?e.pick_omit:0), "position": mat_pos.row&&mat_pos.col?(mat_pos.row + "-" + mat_pos.col):"" })
        });
               
        const is_omit = upd_mat.some((e:any)=>e.pick_omit>0);
        this.box_obj_ins = { "id_box": this.idBoxOpen, "id_station": this.station.id_dist_racks, "status": (is_omit?2:1), "hasMat": true, "materiales": upd_mat, "init": this.boxOpenAt, "scan_log": this.boxScanLog };
        if(this.userObj.tipo!=='q') {
          this.saveStatus(this.box_obj_ins);
        }
        else {
          Swal.fire({
            icon: 'success',
            title: 'Articulo escaneado',
            timer: 1000,
            showConfirmButton: false
          });
        }
      }
      else {
        Swal.fire({
          icon: 'success',
          title: 'Articulo escaneado',
          timer: 1000,
          showConfirmButton: false
        });
      }
    }
    else {
      this.boxScanLog.push({ "type": "scan_error", time: new Date(), "scan": matScan.sku });
      Swal.fire({
        icon: 'warning',
        title: 'El SKU no coincide',
        text: 'El SKU escaneado no es el que indica la pantalla, verifica que el material tomado es el que se solicita.',
        timer: 3000,
        showConfirmButton: false
      });
    }
  }

  cleanBox() {
    this.boxOpen = false;
    this.boxObj = [];
    this.next_scan = {};
    this.box_readonly = false;
  }

  checkBoxPend() {
    return this.listMaterials.some((e:any)=>e.pick!=e.cantidad)
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if(this.code!='Cerrar sesion' && this.code!='Tutorial' && !this.tutorialOpen) {
        if(this.code=='cancel') {
          this.cleanBox();
        }
        else if(this.boxOpen) {
          if(this.box_readonly) {
            this.cleanBox();
          }
          else if( this.checkBoxPend() && !this.next_scan && this.userObj.tipo==='q' && !isNaN(+this.code) && this.idBoxOpen === parseInt(this.code)) {
            this.saveStatus(this.box_obj_ins);
          }
          else if(!this.next_scan && this.userObj.tipo==='q' && !isNaN(+this.code) && this.idBoxOpen === parseInt(this.code)) {
            this.stopRecording();          
            this.cleanBox();
          }
          else {
            let matScan;
            if(this.code === "omitir") matScan = { sku: "omitir" };
            else matScan = this.listMaterials.find((e:any)=>e.sku.toUpperCase()===this.code.toUpperCase()&&e.pick<e.cantidad);
            if(matScan) {
              this.scanProduct(matScan);
            }
            else {
              this.boxScanLog.push({ "type": "scan_error", time: new Date(), "scan": this.code });
              Swal.fire({
                icon: 'warning',
                title: 'El SKU no coincide',
                text: 'El SKU escaneado no es el que indica la pantalla, verifica que el material tomado es el que se solicita.',
                timer: 3000,
                showConfirmButton: false
              });
            }
          }
        }
        else {
          this.loadBox(this.code);
        }
      }
      this.openKeyEventSB(this.code);
      this.code="";
    } else {
      this.code += event.key;
    }
  }

  openKeyEventSB(typed: string) {
    this._snackBar.openFromComponent(KeyeventComponent, {
      duration: 2000,
      horizontalPosition: "start",
      data: {
        typed: typed
      }
    });
  }
}
